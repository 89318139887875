// Generated by Framer (cf240c2)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const MotionDivWithFX = withFX(motion.div);

const serializationHash = "framer-dGju0"

const variantClassNames = {Q_rV4pSA9: "framer-v-1sfhvfe"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const transition2 = {bounce: 0.1, delay: 0, duration: 3, type: "spring"}

const animation = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 60, y: 32}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Q_rV4pSA9", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1sfhvfe", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Q_rV4pSA9"} ref={ref ?? ref1} style={{...style}}><MotionDivWithFX __framer__loop={animation} __framer__loopEffectEnabled __framer__loopRepeatDelay={1} __framer__loopRepeatType={"mirror"} __framer__loopTransition={transition2} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={"framer-14u04ru"} layoutDependency={layoutDependency} layoutId={"VejjHWLWV"}><motion.div className={"framer-caun14"} data-border layoutDependency={layoutDependency} layoutId={"egBZoQt_6"} style={{"--border-bottom-width": "1px", "--border-color": "rgb(34, 34, 34)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 19, borderBottomRightRadius: 19, borderTopLeftRadius: 19, borderTopRightRadius: 19}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p>coding</motion.p></React.Fragment>} className={"framer-19l0qp2"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"mb5N5pSOR"} verticalAlignment={"top"} withExternalLayout/></motion.div><Image background={{alt: "", fit: "fit", loading: getLoadingLazyAtYPosition((((componentViewport?.y || 0) + 0) + 0.5000000000000071)), pixelHeight: 464, pixelWidth: 489, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/xnUGuAEpzW9OcWNAzhMKgiMqbw.png"}} className={"framer-huaunr"} layoutDependency={layoutDependency} layoutId={"GafSVX6Cu"}/></MotionDivWithFX></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-dGju0.framer-1c565m5, .framer-dGju0 .framer-1c565m5 { display: block; }", ".framer-dGju0.framer-1sfhvfe { height: 87px; overflow: hidden; position: relative; width: 207px; }", ".framer-dGju0 .framer-14u04ru { flex: none; height: 32px; left: 0px; overflow: visible; position: absolute; top: 0px; width: 146px; }", ".framer-dGju0 .framer-caun14 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 26px; justify-content: center; left: calc(48.630136986301395% - 137px / 2); overflow: visible; padding: 0px; position: absolute; top: calc(53.12500000000002% - 26px / 2); width: 137px; }", ".framer-dGju0 .framer-19l0qp2 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-dGju0 .framer-huaunr { flex: none; height: 11px; left: calc(95.20547945205482% - 12px / 2); overflow: visible; position: absolute; top: calc(18.75000000000002% - 11px / 2); width: 12px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-dGju0 .framer-caun14 { gap: 0px; } .framer-dGju0 .framer-caun14 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-dGju0 .framer-caun14 > :first-child { margin-left: 0px; } .framer-dGju0 .framer-caun14 > :last-child { margin-right: 0px; } }", ".framer-dGju0[data-border=\"true\"]::after, .framer-dGju0 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 87
 * @framerIntrinsicWidth 207
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerQEpseXLqd: React.ComponentType<Props> = withCSS(Component, css, "framer-dGju0") as typeof Component;
export default FramerQEpseXLqd;

FramerQEpseXLqd.displayName = "moving button Coding";

FramerQEpseXLqd.defaultProps = {height: 87, width: 207};

addFonts(FramerQEpseXLqd, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})